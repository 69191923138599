/**
 * Global configurations that applies to both apps/api and apps/web.
 */

/*
Standard sizes and ratios for images:
avatar:
  1:1
  stored as: 800x800

banner
  3:1
  stored as: 1500:500

The "stored as" size is what we *store*, the delivered image size is allowed to be different.
Having a higher stored size allows us for flexibility in UI design in the future.
*/

export const config = {
  storage: {
    publicImagesBucket: "public_images",
    // This bucket is used for data transfers, files in it are ephemeral.
    // It's meant to be used in this flow:
    // 1. Frontend uploads a file to the transfer bucket
    // 2. Frontend  sends a request to the backend with the file's name
    // 3. Backend downloads the file from the transfer bucket
    // 4. Backend finishes processing the file, and delete it from the bucket
    // We must do this instead of directly sending the file because TRPC doesn not support file uploads
    transferBucket: "transfer"
  },

  avatar: {
    width: 500,
    height: 500,
    quality: 100,
    stored: {
      width: 800,
      height: 800,
      quality: 100
    }
  },
  banner: {
    width: 600,
    height: 200,
    quality: 80,
    stored: {
      width: 800,
      height: 800,
      quality: 100
    }
  },
  tier: {
    free: {
      pledgeAmount: 0,
      maxContextTokens: 3072,
      maxResponseTokens: 1024,
    },
    "tier-1": {
      pledgeAmount: 1500,
      maxContextTokens: 4096,
      maxResponseTokens: 2048,
    },
    "tier-2": {
      pledgeAmount: 2500,
      maxContextTokens: 4096,
      maxResponseTokens: 2048,
    }
  },
  usageResetIntervalHours: 3
};
