import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle
} from "@moe/oss/ui/alert-dialog";
import { DialogConfig } from "@moe/priv/types/types";

interface Props {
  config?: DialogConfig;
  setConfig: (config?: DialogConfig) => void;
}

export function DialogProvider({ config, setConfig }: Props) {
  return (
    <>
      {config && (
        <AlertDialog open={!!config}>
          <AlertDialogContent
            className="border-none outline-none"
            onKeyDown={(e) => {
              if (e.key === "Escape") {
                setConfig(undefined);
              }
            }}
          >
            <AlertDialogHeader>
              <AlertDialogTitle>{config.title}</AlertDialogTitle>
              <AlertDialogDescription>{config.description}</AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <div className="flex items-center justify-end gap-2">
                <AlertDialogCancel
                  className="w-20"
                  onClick={() => {
                    config.onCancel?.();
                    setConfig(undefined);
                  }}
                >
                  {config.cancelLabel || "Cancel"}
                </AlertDialogCancel>
                <AlertDialogAction
                  className="w-20"
                  onClick={() => {
                    config.onAction();
                    setConfig(undefined);
                  }}
                >
                  {config.actionLabel}
                </AlertDialogAction>
              </div>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      )}
    </>
  );
}
