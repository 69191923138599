import { Chat, ChatInsert, ChatSettings, ChatUpdate } from "@moe/priv/model/chat";
import { Json, TablesInsert } from "@moe/priv/types/sb-types";
import { ChatPreview, DeepPartial } from "@moe/priv/types/types";
import { QueryData } from "@supabase/supabase-js";
import { ID } from "@web/accessor/base";
import { sb } from "@web/lib/supabase";
import { Client } from "@web/lib/trpc";

type R = Chat;
type I = ChatInsert;
type U = ChatUpdate;

interface GetPageOptions {
  page: number;
  pageSize: number;
  order: "asc" | "desc";
  filter: {
    searchText: string;
    isStudio: boolean;
  };
}

interface GetPreviewPageOptions {
  page: number;
  pageSize: number;
}

namespace Q {
  export namespace All {
    export const selector = `id, characters(id), persona_id, is_studio, created_at, updated_at, memory, settings`;
    const builder = sb.from("chats").select(selector);
    export type Builder = typeof builder;
    export type Data = QueryData<typeof builder>[number];
  }
}

interface Constructor {
  userID?: string;
  trpc: Client;
}

export class ChatAccessor {
  private readonly userID?: string;
  private readonly trpc: Client;

  constructor({ userID, trpc }: Constructor) {
    this.userID = userID;
    this.trpc = trpc;
  }

  /**
   * Retrieves a chat or a list of chats by their ID.
   *
   * @param id - The ID or an array of IDs of the chats to retrieve.
   * @returns A promise that resolves to the chat or an array of chats with the specified ID(s).
   */
  async getByID(id: ID): Promise<R>;
  async getByID(id: ID[]): Promise<R[]>;
  async getByID(id: ID | ID[]): Promise<R | R[]> {
    const q = sb.from("chats").select(Q.All.selector);
    if (Array.isArray(id)) {
      const { data, error } = await q.in("id", id);
      if (error) throw error;
      return this.processAllQuery(data);
    }
    const { data, error } = await q.eq("id", id).single();
    if (error) throw error;

    return this.processAllQuery(data);
  }

  /**
   * Creates a new chat or multiple chats.
   *
   * @param value - The chat data or an array of chat data to create.
   * @returns A promise that resolves to the created chat(s).
   */
  async create(value: I): Promise<number>;
  async create(value: I[]): Promise<number[]>;
  async create(value: I | I[]): Promise<number | number[]> {
    if (Array.isArray(value)) return Promise.all(value.map((v) => this.create(v)));
    const chatValue: TablesInsert<"chats"> = {
      persona_id: value.personaID,
      is_studio: value.isStudio ?? false,
      memory: value.memory
    };

    const { data, error } = await sb.from("chats").insert(chatValue).select(Q.All.selector).single();

    if (error) throw error;
    const { error: junctionError } = await sb.from("chats_characters").insert(
      value.charIDs.map((charID) => ({
        chat_id: data.id,
        character_id: charID
      }))
    );
    if (junctionError) throw junctionError;
    return data.id;
  }

  /**
   * Links one or more characters to a chat.
   *
   * @param chatID - The ID of the chat to link characters to
   * @param characterID - A single character ID or array of character IDs to link
   */
  async linkCharacter(chatID: number, characterID: number | number[]): Promise<void> {
    const ids = Array.isArray(characterID) ? characterID : [characterID];

    const { error } = await sb.from("chats_characters").insert(
      ids.map((id) => ({
        chat_id: chatID,
        character_id: id
      }))
    );

    if (error) throw error;
  }

  /**
   * Unlinks one or more characters from a chat.
   *
   * @param chatID - The ID of the chat to unlink characters from
   * @param characterID - A single character ID or array of character IDs to unlink
   */
  async unlinkCharacter(chatID: number, characterID: number | number[]): Promise<void> {
    const ids = Array.isArray(characterID) ? characterID : [characterID];
    const { error } = await sb.from("chats_characters").delete().eq("chat_id", chatID).in("character_id", ids);
    if (error) throw error;
  }

  /**
   * Updates an existing chat or multiple chats.
   *
   * @param value - The value(s) to update the chat with
   * @returns A promise that resolves to the updated chat(s).
   */
  async update(value: U | U[]): Promise<void> {
    if (Array.isArray(value)) {
      Promise.all(value.map(async (v) => this.update(v)));
      return;
    }

    const { error } = await sb
      .from("chats")
      .update({
        persona_id: value.personaID,
        memory: value.memory,
        settings: value.settings as Json
      })
      .eq("id", value.id);

    // Associate characters to chats
    if (value.charIDs) {
      const { error: deleteError } = await sb.from("chats_characters").delete().eq("chat_id", value.id);
      if (deleteError) throw deleteError;
      const { error: insertError } = await sb.from("chats_characters").insert(
        value.charIDs.map((id) => ({
          chat_id: value.id,
          character_id: id
        }))
      );
      if (insertError) throw insertError;
    }

    if (error) throw error;
  }

  /**
   * Removes a chat or multiple chats.
   *
   * @param id - The ID or an array of IDs of the chats to remove.
   * @returns A promise that resolves when the operation is complete.
   */
  async delete(id: number | number[]): Promise<void> {
    if (Array.isArray(id)) {
      Promise.all(id.map(async (i) => this.delete(i)));
      return;
    }
    await this.trpc.chat.delete.mutate(id);
  }

  /**
   * Retrieves a page of chats based on the provided options.
   *
   * @param options - The options for pagination, ordering, and filtering.
   * @returns A promise that resolves to an array of chats.
   */
  async getPage(options?: DeepPartial<GetPageOptions>): Promise<R[]> {
    if (!this.userID) throw new Error("User ID is required to get chats.");

    const opt = this.normalizeGetPageOptions(options);
    const q = sb.from("chats").select(Q.All.selector);
    const rangeStart = opt.page * opt.pageSize;
    const rangeEnd = rangeStart + opt.pageSize - 1;

    const ascending = opt.order === "asc";
    q.eq("is_studio", opt.filter.isStudio)
      .order("created_at", { ascending })
      .range(rangeStart, rangeEnd)
      .eq("created_by", this.userID);
    if (opt.filter.searchText) throw new Error("Search text is not implemented yet.");

    const { data, error } = await q;
    if (error) throw error;
    return this.processAllQuery(data);
  }

  /**
   * Retrieves a page of chat previews for the authenticated user.
   * Ordered by most recent interactions
   *
   * @param page - The page number to retrieve (default is 0).
   * @param pageSize - The number of chat previews to return per page (default is 25).
   * @returns An array of ChatPreview objects.
   */
  async getPreviewPage(options: GetPreviewPageOptions): Promise<ChatPreview[]> {
    return this.trpc.chat.getPreviewPage.query(options);
  }

  private processAllQuery(value: Q.All.Data): R;
  private processAllQuery(value: Q.All.Data[]): R[];
  private processAllQuery(value: Q.All.Data | Q.All.Data[]): R | R[] {
    if (Array.isArray(value)) return value.map((v) => this.processAllQuery(v));

    return {
      id: value.id,
      charIDs: value.characters.map((c) => c.id),
      personaID: value.persona_id,
      isStudio: value.is_studio,
      createdAt: value.created_at,
      updatedAt: value.updated_at ?? undefined,
      memory: value.memory ?? undefined,
      settings: (value.settings as ChatSettings) ?? undefined
    };
  }
  private normalizeGetPageOptions(options?: DeepPartial<GetPageOptions>): GetPageOptions {
    return {
      page: options?.page ?? 0,
      pageSize: options?.pageSize ?? 30,
      order: options?.order ?? "desc",
      filter: {
        searchText: options?.filter?.searchText ?? "",
        isStudio: options?.filter?.isStudio ?? false
      }
    };
  }
}
