import { CharacterService } from "@moe/priv/model/character";
import { z } from "zod";

const { rankingSchema, periodSchema, orderSchema } = CharacterService;

export const PAGE_SIZE = 30;

export const searchSchema = z.object({
  ranking: rankingSchema.optional(),
  searchText: z.string().optional(),
  page: z.number().min(1).optional().catch(1),
  pageSize: z.number().min(1).optional(),
  order: orderSchema.optional(),
  period: periodSchema.optional(),
  nsfwOK: z.boolean().optional(),
  tags: z.array(z.string()).optional()
});

const getAssetUrl = (path: string): string => {
  return new URL(`./assets/${path}`, import.meta.url).href;
};

// Index Banner Greeting -----------------------------------------------------------------------------------------------
const GENERIC_BROWSERS = new Set(["UNKNΘWN USΣR", "Mobile", "Safari"]);

export const greetAgent = (): [string, string | null] => {
  const now = new Date();
  const userAgent = detectUserAgent();
  const isGenericBrowser = GENERIC_BROWSERS.has(userAgent);

  const hours = now.getHours() % 12 || 12;
  const meridiem = now.getHours() >= 12 ? "PM" : "AM";
  const formattedDate = `${now.getMonth() + 1}/${now.getDate()}`;
  const formattedMinutes = now.getMinutes().toString().padStart(2, "0");

  const browserImage = isGenericBrowser ? null : getBrowserImage(userAgent);

  return [`DispΔtcth ${userAgent} - ${formattedDate}, ${hours}:${formattedMinutes} ${meridiem}`, browserImage] as const;
};

const detectUserAgent = (): string => {
  const ua = navigator.userAgent;

  if (/mobile|android|iphone|ipad/i.test(ua)) return "Mobile";

  const userAgentMatch = ua.match(/(?:Chrome|Edg|Firefox|Safari)\/[\d.]+/);
  if (!userAgentMatch) return "UNKNΘWN USΣR";

  const userAgent = userAgentMatch[0].split("/")[0];

  if (userAgent === "Chrome" && ua.includes("Edg")) return "Edge";

  return userAgent;
};

const getBrowserImage = (userAgent: string): string => {
  return getAssetUrl(`agents/${userAgent.toLowerCase()}.webp`);
};

export class textScramble {
  private el: HTMLElement;
  private chars: string;
  private queue: { from: string; to: string; start: number; end: number; char?: string }[];
  private speed: number;
  private frame = 0;
  private frameRequest = 0;
  private resolve!: () => void;

  constructor(el: HTMLElement, _strings: string[] = [], speed = 1) {
    this.el = el;
    this.chars = "!<>-_\\/[]{}—=+*^?#________";
    this.queue = [];
    this.speed = speed;
    this.update = this.update.bind(this);
  }

  public setText(newText: string): Promise<void> {
    const oldText = this.el.textContent || "";
    const length = Math.max(oldText.length, newText.length);
    const promise = new Promise<void>((resolve) => (this.resolve = resolve));
    this.queue = [];

    for (let i = 0; i < length; i++) {
      const from = oldText[i] || "";
      const to = newText[i] || "";
      const start = Math.floor((Math.random() * 40) / this.speed);
      const end = start + Math.floor((Math.random() * 40) / this.speed);
      this.queue.push({ from, to, start, end });
    }

    cancelAnimationFrame(this.frameRequest);
    this.frame = 0;
    this.update();
    return promise;
  }

  private update(): void {
    const output: string[] = [];
    let complete = 0;

    for (let i = 0, len = this.queue.length; i < len; i++) {
      const { from, to, start, end } = this.queue[i];
      let { char } = this.queue[i];

      if (this.frame >= end) {
        complete++;
        output.push(to);
      } else if (this.frame >= start) {
        if (!char || Math.random() < 0.28) {
          char = this.randomChar();
          this.queue[i].char = char;
        }
        output.push(`<span class="dud">${char}</span>`);
      } else {
        output.push(from);
      }
    }

    this.el.innerHTML = output.join("");

    if (complete !== this.queue.length) {
      this.frameRequest = requestAnimationFrame(this.update);
      this.frame++;
    } else {
      this.resolve();
    }
  }

  private randomChar(): string {
    return this.chars[Math.floor(Math.random() * this.chars.length)];
  }
}
