import { Client } from "@web/lib/trpc";

interface Constructor {
  userID?: string;
  trpc: Client;
}
export class AccountAccessor {
  private readonly userID?: string;
  private readonly trpc: Client;

  constructor({ userID, trpc }: Constructor) {
    this.userID = userID;
    this.trpc = trpc;
  }

  /**
   * Initiates account deletion process by generating and sending a verification code
   */
  async startDelete(): Promise<void> {
    return await this.trpc.account.startDelete.mutate();
  }

  /**
   * Submits the account deletion process by verifying the provided verification code.
   */
  async submitDelete(code: string): Promise<void> {
    return await this.trpc.account.submitDelete.mutate({ code });
  }
}
