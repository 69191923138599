import { Dialog, DialogContent } from "@moe/oss/ui/dialog";
import { ModalConfig } from "@moe/priv/types/types";
import { useRouter } from "@tanstack/react-router";
import { useAppContext } from "@web/route-services/root/AppContext";
import EventEmitter from "events";
import { useEffect, useState } from "react";
import { Sheet } from "react-modal-sheet";
import { RemoveScroll } from "react-remove-scroll";

export const modalEE = new EventEmitter();

/**
 * This component wraps the entire component tree and provides modal (desktop) and drawer (mobile) functionality.
 */
export function ModalProvider() {
  const { isDesktopScreen } = useAppContext();
  const [config, setConfig] = useState<ModalConfig>();

  // Using event emitters to create and close modal instead of react context to prevent expensive re-renders
  // This is confusing when it's used liberally, only do this for hot paths.
  useEffect(() => {
    const createModalHandler = (newConfig: any) => {
      // If there is already a modal open, close it first, then queue the new one
      if (config) {
        setConfig(undefined);
        setTimeout(() => {
          setConfig(newConfig);
        }, 200);
      } else {
        setConfig(newConfig);
      }
    };
    const closeModalHandler = () => setConfig(undefined);
    modalEE.on("createModal", createModalHandler);
    modalEE.on("closeModal", closeModalHandler);

    return () => {
      modalEE.off("createModal", createModalHandler);
      modalEE.off("closeModal", closeModalHandler);
    };
  }, [config]);

  const router = useRouter();

  // On changing routes, close modal
  useEffect(() => {
    const unsubscribe = router.history.subscribe(() => {
      modalEE.emit("closeModal");
    });

    return () => {
      unsubscribe();
    };
  }, [router.history]);

  // Desktop regular modal
  if (isDesktopScreen) {
    return (
      <>
        <Dialog
          open={!!config}
          onOpenChange={(o) => {
            if (!o) setConfig(undefined);
          }}
        >
          <DialogContent
            onOpenAutoFocus={(e) => e.preventDefault()}
            className="border-line w-fit max-w-none border p-0 outline-none"
            onInteractOutside={(e) => {
              if (!config) return;
              if (config.onInteractOutside) config.onInteractOutside(e);
            }}
          >
            {config?.content}
          </DialogContent>
        </Dialog>
      </>
    );
  }

  // Mobile modal sheet / drawer
  return (
    <>
      <RemoveScroll enabled={!!config}>
        <Sheet
          isOpen={!!config}
          disableScrollLocking={true}
          onClose={() => {
            setConfig(undefined);
          }}
          detent="content-height"
        >
          <Sheet.Container>
            <Sheet.Header>
              <div className="bg-float flex w-full items-center justify-center pb-4 pt-4">
                <div className="bg-muted h-2 w-20 rounded-sm"></div>
              </div>
            </Sheet.Header>
            <Sheet.Content className="bg-float" disableDrag={config?.headerDragOnly === true}>
              <Sheet.Scroller>{config?.content}</Sheet.Scroller>
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop
            onTap={() => {
              setConfig(undefined);
            }}
          />
        </Sheet>
      </RemoveScroll>
    </>
  );
}
